import { defineStore } from "pinia";

export const useShareStore = defineStore("share", {
	state: () => ({
		isLoading: null,
		loadingText: "",
		errorText: "",
		isError: false,
	}),
	actions: {
		setIsLoading(value) {
			this.isLoading = value;
		},
		setLoadingText(value) {
			this.loadingText = value;
		},
		setErrorText(value) {
			this.errorText = value;
		},
		setIsError(value) {
			this.isError = value;
		},
	},
});
