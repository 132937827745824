<template>
	<v-container
		fluid
		class="d-flex justify-center align-center"
		style="height: 100vh;"
	>
		<v-card outlined class="d-flex flex-column pa-3">
			<v-card-title class="pa-0 ma-0 mb-4 justify-center font-weight-light text-h6">
				<div class="pt-0 text-center" style="word-break: normal;">
					{{ errorText }}
				</div>
			</v-card-title>
			<v-icon size="32" color="error">
				mdi-alert-circle-outline
			</v-icon>
		</v-card>
	</v-container>
</template>

<script>
import { useShareStore } from "@/store/shareStore";
import { mapStores } from "pinia";

export default {
	name: "ErrorComponent",
	computed: {
		...mapStores(useShareStore),
		errorText() {
			return this.shareStore.errorText;
		},
	},
};
</script>
