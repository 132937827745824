<template>
	<v-app>
		<LoadingDialog v-if="isLoading" />
		<ErrorComponent v-if="isErrorful" />
		<PreviewHeader v-if="isPreview" />
		<ReviewHeader v-if="isReview && collaboratorId" />
		<ReviewSidebar v-if="isReview && !isSmallDevice && collaboratorId" />
		<ChamaileonFlashToast />
		<v-main :style="style">
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import chamaileonFrontendModules from "@edmdesigner/chamaileon-frontend-modules";
import { useReviewStore } from "@/store/reviewStore";
import { useShareStore } from "@/store/shareStore";
import { useWhitelabelStore } from "@/store/whitelabelStore";
import loggerHooks from "@/utils/loggerHooks";
import { mapStores } from "pinia";
import Vue from "vue";

export default {
	name: "App",
	data() {
		return {
			hasAppliedWhitelabel: false,
		};
	},
	computed: {
		...mapStores(useReviewStore, useShareStore, useWhitelabelStore),
		isReview() {
			return this.$route.path.includes("/review");
		},
		isPreview() {
			return this.$route.path.includes("/preview");
		},
		isLoading() {
			return this.shareStore.isLoading;
		},
		isErrorful() {
			return (this.isReview && !this.collaboratorId) || this.shareStore.isError;
		},
		isSmallDevice() {
			return this.$vuetify.breakpoint.width < 768;
		},
		collaboratorId() {
			return this.reviewStore.collaboratorId;
		},
		style() {
			return {
				"background-color": !this.whitelabelStore.isDefault ? "var(--v-background-base) !important" : "",
			};
		},
	},
	mounted() {
		Object.keys(loggerHooks).forEach((hookName) => {
			Vue.set(chamaileonFrontendModules.storeModules.logger(), "hooks", {
				[hookName]: loggerHooks[hookName],
			});
		});
	},
	updated() {
		const hasWhitelabelNotApplied = this.whitelabelStore.isCurrentValid && !this.hasAppliedWhitelabel;
		if (hasWhitelabelNotApplied) this.updateWithWhitelabel();
	},
	methods: {
		updateWithWhitelabel() {
			if (!this.whitelabelStore.isCurrentValid) return;

			// Update favicon
			const favIconUrl = this.whitelabelStore.current?.favIconUrl;
			if (favIconUrl) {
				let icon = document.querySelector("link[rel~='icon']");
				if (!icon) {
					icon = document.createElement("link");
					icon.rel = "icon";
					document.getElementsByTagName("head")[0].appendChild(icon);
				}
				icon.href = favIconUrl;
			}

			// Update vuetify
			const colorSchema = this.whitelabelStore.current?.colorSchema;

			if (colorSchema) {
				if (colorSchema.button) this.$vuetify.theme.themes.light.primary = colorSchema.button;
				if (colorSchema.background) this.$vuetify.theme.themes.light.background = colorSchema.background;
				if (colorSchema.border) this.$vuetify.theme.themes.light.accent = colorSchema.border;
				if (colorSchema.header) this.$vuetify.theme.themes.light.header = colorSchema.header;
			}

			this.hasAppliedWhitelabel = true;
		},
	},
};
</script>
<style>
	#chamaileon-logo {
		fill: var(--v-primary-base);
	}
	.v-sheet, .v-tabs-bar {
		background-color: var(--v-background-base) !important;
	}
	.ellipsis {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
