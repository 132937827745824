import Vue from "vue";
import VueRouter from "vue-router";
import { default as VueGtm } from "@gtm-support/vue2-gtm";
import { useDocumentStore } from "@/store/documentStore";
import { usePusherStore } from "@/store/pusherStore";
import { useReviewStore } from "@/store/reviewStore";
import { useShareStore } from "@/store/shareStore";
import { useWhitelabelStore } from "@/store/whitelabelStore";
import i18n from "@/plugins/i18n";

const Preview = () => import("@/views/Preview.vue");
const Review = () => import("@/views/Review.vue");

Vue.use(VueRouter);

const routes = [
	{
		path: "/preview/:previewHash",
		name: "Preview",
		component: Preview,
		beforeEnter: async (to, from, next) => {
			try {
				useShareStore().setIsLoading(true);
				useShareStore().setLoadingText(i18n.t("loading.preview"));

				await useDocumentStore().loadOneForPreview(to.params.previewHash);
				await useWhitelabelStore().load({ workspaceId: useDocumentStore().current.workspaceId });

				next();
			} catch (error) {
				useShareStore().setErrorText(i18n.t("errorMessages.previewCouldNotLoad"));
				useShareStore().setIsLoading(false);
				useShareStore().setIsError(true);
			}
		},
	},
	{
		path: "/review/:reviewHash",
		name: "Review",
		component: Review,
		beforeEnter: async (to, from, next) => {
			try {
				useShareStore().setIsLoading(true);
				useShareStore().setLoadingText(i18n.t("loading.review"));

				await useReviewStore().loadOne(to.params.reviewHash);
				await Promise.all([
					useReviewStore().loadComments(),
					useReviewStore().loadCollaborators(),
					useDocumentStore().loadOne({
						workspaceId: useReviewStore().current.workspaceId,
						emailId: useReviewStore().current.emailId,
					}),
					useWhitelabelStore().load({ workspaceId: useReviewStore().current.workspaceId }),
				]);

				usePusherStore().init();

				const collaboratorId = to.query.collaboratorId;
				if (collaboratorId) {
					const isCollaboratorPresent = useReviewStore().collaborators.some(el => el._id === collaboratorId);
					if (isCollaboratorPresent) {
						useReviewStore().collaboratorId = collaboratorId;
					} else {
						throw new Error("collaboratorIsNotPresent");
					}
				} else {
					throw new Error("collaboratorIsMandatory");
				}

				next();
			} catch (error) {
				if (error.message === "collaboratorIsMandatory") useShareStore().setErrorText(i18n.t("errorMessages.collaboratorIsMandatory"));
				else if (error.message === "collaboratorIsNotPresent") useShareStore().setErrorText(i18n.t("errorMessages.collaboratorIsNotPresent"));
				else useShareStore().setErrorText(i18n.t("errorMessages.reviewCouldNotLoad"));
				useShareStore().setIsLoading(false);
				useShareStore().setIsError(true);
			}
		},
	},
	{
		path: "/reviews/:reviewId",
		name: "OldReview",
		beforeEnter: async (to, from, next) => {
			const reviewId = to.params.reviewId;
			const collaboratorId = to.query.collaboratorId;
			const reviewBaseUrl = await useReviewStore().getReviewBaseUrl(reviewId);

			next({ path: `/review/${reviewBaseUrl}`, query: { collaboratorId } });
		},
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);

	return originalPush.call(this, location).catch((err) => {
		if (VueRouter.isNavigationFailure(err)) return err;
		return Promise.reject(err);
	});
};

router.afterEach((to) => {
	Vue.nextTick(() => {
		document.title = to.meta.title || "Share";
	});
});

Vue.use(VueGtm, {
	id: "GTM-THZSD53",
	enabled: process.env.NODE_ENV !== "development",
	vueRouter: router,
});

export default router;
